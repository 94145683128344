var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cont-form" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "jobNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "运单号",
                                rows: 3,
                                type: "textarea"
                              },
                              model: {
                                value: _vm.queryForm.jobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "jobNo", $$v)
                                },
                                expression: "queryForm.jobNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜号", prop: "cabinetNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cabinetNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "cabinetNo", $$v)
                                },
                                expression: "queryForm.cabinetNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "是否混箱", prop: "isMixed" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.isMixed,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "isMixed", $$v)
                                  },
                                  expression: "queryForm.isMixed"
                                }
                              },
                              _vm._l(_vm.yseOrNo, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "报关方式", prop: "customsType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.customsType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "customsType", $$v)
                                  },
                                  expression: "queryForm.customsType"
                                }
                              },
                              _vm._l(_vm.customsType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "清关方式", prop: "taxType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.taxType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "taxType", $$v)
                                  },
                                  expression: "queryForm.taxType"
                                }
                              },
                              _vm._l(_vm.taxType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 底单导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPortPri,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPortPri }
                },
                [_vm._v(" 报关资料导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.openColumnSettings }
                },
                [_vm._v(" 设置列 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "选择显示的列", visible: _vm.showColumnSettings },
          on: {
            "update:visible": function($event) {
              _vm.showColumnSettings = $event
            },
            close: _vm.closeColumnSettings
          }
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.selectedColumns,
                callback: function($$v) {
                  _vm.selectedColumns = $$v
                },
                expression: "selectedColumns"
              }
            },
            [
              _c(
                "div",
                { staticClass: "columns-list" },
                _vm._l(_vm.allColumns, function(column, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: column.field,
                      class: { "first-in-row": index % 5 === 0 },
                      attrs: { label: column.field },
                      on: {
                        change: function($event) {
                          return _vm.updateIsAllSelected()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(column.title) + " ")]
                  )
                }),
                1
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function($event) {
                      return _vm.handleAllSelectionChange()
                    }
                  },
                  model: {
                    value: _vm.isAllSelected,
                    callback: function($$v) {
                      _vm.isAllSelected = $$v
                    },
                    expression: "isAllSelected"
                  }
                },
                [_vm._v(" 全选 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeColumnSettings }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 420px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                data: _vm.tableData,
                height: "100%",
                "row-config": { isHover: true, isCurrent: true, height: 35 },
                "scroll-y": { enabled: true },
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectAllEvent
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  title: "序号",
                  type: "seq",
                  width: "70"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var seq = ref.seq
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              seq +
                                (_vm.queryForm.pageNum - 1) *
                                  _vm.queryForm.pageSize
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.selectedColumns.includes("cabinetNo")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "cabinetNo",
                      title: "柜号",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("jobNo")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "jobNo",
                      title: "运单号",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("cusProductCnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "cusProductCnName",
                      title: "客户中文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("cusProductEnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "cusProductEnName",
                      title: "客户英文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("hsCode")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "hsCode",
                      title: "海关编码",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportCnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportCnName",
                      title: "申报中文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportEnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportEnName",
                      title: "申报英文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("hsCodeCn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "hsCodeCn",
                      title: "中国海关编码",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportFactor")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportFactor",
                      title: "中国申报要素",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("materialCn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "materialCn",
                      title: "中文材质",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("materialEn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "materialEn",
                      title: "英文材质",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("childJobNo")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "childJobNo",
                      "show-overflow-tooltip": "",
                      title: "子单号",
                      width: "240"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("brand")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "brand",
                      title: "品牌",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("brandType")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "brandType",
                      title: "品牌类型",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("specs")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "specs",
                      title: "规格",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("uses")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "uses",
                      title: "用途",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("goodsType")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "goodsType",
                      title: "货物类型",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("unit")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "unit",
                      title: "单位",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("pcs")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "pcs",
                      "show-overflow-tooltip": "",
                      title: "件数",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("netWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "netWeight",
                      title: "净重",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("receiveNetWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "receiveNetWeight",
                      title: "收货总净重",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("qty")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "qty",
                      title: "数量",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("vol")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "vol",
                      "show-overflow-tooltip": "",
                      title: "体积",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("splitVol")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "splitVol",
                      "show-overflow-tooltip": "",
                      title: "拆分体积",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("grossWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "grossWeight",
                      title: "毛重",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("splitGrossWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "splitGrossWeight",
                      title: "拆分毛重",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("receiveGrossWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "receiveGrossWeight",
                      title: "收货总实重",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("spliTreceiveGrossWeight")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "spliTreceiveGrossWeight",
                      title: "拆分收货总实重",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("purchasePrices")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "purchasePrices",
                      title: "单价",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("totalPrice")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "totalPrice",
                      title: "总价",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("length")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "length",
                      "show-overflow-tooltip": "",
                      title: "长",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("width")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "width",
                      "show-overflow-tooltip": "",
                      title: "宽",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("height")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "height",
                      "show-overflow-tooltip": "",
                      title: "高",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("transactionUrl")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "transactionUrl",
                      fixed: "right",
                      "show-overflow-tooltip": "",
                      title: "链接",
                      width: "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: row.transactionUrl,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.transactionUrl) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      539832458
                    )
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("customsType")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "customsType",
                      title: "报关方式",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("taxType")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "taxType",
                      fixed: "right",
                      title: "清关方式",
                      width: "80"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createBy")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createBy",
                      title: "创建人",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createTime")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createTime",
                      title: "创建时间",
                      width: "160"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("isMixed")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "isMixed",
                      fixed: "right",
                      title: "是否混装",
                      width: "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: row.isMixed ? "success" : "danger"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.isMixed ? "是" : "否") +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3260745215
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 全部合计： "),
        _c("span", [
          _vm._v(
            "订单毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "订单净重：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalNetWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货净重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveNetWeight))
          )
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.fomr.totalPcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.fomr.totalQty))]),
        _c("span", [
          _vm._v("金额：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalPrice)))
        ]),
        _c("span", [
          _vm._v("体积：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalVol)))
        ])
      ]),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 选中数据合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.selectForm.grossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.selectForm.netWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.selectForm.receiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.selectForm.receiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.selectForm.pcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.selectForm.qty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.selectForm.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.selectForm.vol))])
      ]),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [100, 200, 300, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("Preview", { ref: "preview" }),
      _c("Sync", { ref: "sync" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }