var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "115px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "客户中文品名", prop: "cusProductCnName" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.cusProductCnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "cusProductCnName", $$v)
                          },
                          expression: "queryForm.cusProductCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "客户英文品名", prop: "cusProductEnName" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.cusProductEnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "cusProductEnName", $$v)
                          },
                          expression: "queryForm.cusProductEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申报中文品名", prop: "reportCnName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.reportCnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "reportCnName", $$v)
                          },
                          expression: "queryForm.reportCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申报英文品名", prop: "reportEnName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.reportEnName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "reportEnName", $$v)
                          },
                          expression: "queryForm.reportEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中国海关编码", prop: "hsCode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.hsCode,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "hsCode", $$v)
                          },
                          expression: "queryForm.hsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "进口国海关编码", prop: "importHsCode" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.queryForm.importHsCode,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "importHsCode", $$v)
                          },
                          expression: "queryForm.importHsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fold,
                          expression: "fold"
                        }
                      ],
                      attrs: { label: "关税税率", prop: "rateOfDuty" }
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          max: 100,
                          min: 0,
                          precision: 2,
                          size: "medium",
                          step: 0.1
                        },
                        model: {
                          value: _vm.queryForm.rateOfDuty,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "rateOfDuty", $$v)
                          },
                          expression: "queryForm.rateOfDuty"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fold,
                          expression: "fold"
                        }
                      ],
                      attrs: { label: "风险评估", prop: "riskType" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { clearable: "", placeholder: "请选择类型" },
                          model: {
                            value: _vm.queryForm.riskType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "riskType", $$v)
                            },
                            expression: "queryForm.riskType"
                          }
                        },
                        _vm._l(_vm.riskTypeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fold,
                          expression: "fold"
                        }
                      ],
                      attrs: { label: "是否可承接", prop: "acceptability" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { clearable: "", placeholder: "请选择类型" },
                          model: {
                            value: _vm.queryForm.acceptability,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "acceptability", $$v)
                            },
                            expression: "queryForm.acceptability"
                          }
                        },
                        _vm._l(_vm.acceptabilityList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fold,
                          expression: "fold"
                        }
                      ],
                      attrs: { label: "包税附加费", prop: "surcharge" }
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          max: 100,
                          min: 0,
                          precision: 2,
                          size: "medium",
                          step: 0.1
                        },
                        model: {
                          value: _vm.queryForm.surcharge,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "surcharge", $$v)
                          },
                          expression: "queryForm.surcharge"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handleFold }
                        },
                        [
                          _vm.fold
                            ? _c("span", [_vm._v("合并")])
                            : _c("span", [_vm._v("展开")]),
                          _c("vab-icon", {
                            staticClass: "vab-dropdown",
                            class: { "vab-dropdown-active": !_vm.fold },
                            attrs: { icon: "arrow-up-s-line" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addOrEdit }
                },
                [_vm._v(" 新增 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.queryForm.idList.length > 0,
                    size: "mini",
                    type: "danger"
                  },
                  on: { click: _vm.deleteData }
                },
                [_vm._v(" 删除 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.queryForm.idList.length > 0,
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeStatus(1)
                    }
                  }
                },
                [_vm._v(" 启用 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.queryForm.idList.length > 0,
                    size: "mini",
                    type: "danger"
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeStatus(2)
                    }
                  }
                },
                [_vm._v(" 停用 ")]
              ),
              _c("uploadExcel", {
                attrs: {
                  "down-url": "客户映射关系导入模版.xlsx",
                  "upload-aciton": _vm.bt
                }
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.openColumnSettings }
                },
                [_vm._v(" 设置列 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "选择显示的列", visible: _vm.showColumnSettings },
          on: {
            "update:visible": function($event) {
              _vm.showColumnSettings = $event
            },
            close: _vm.closeColumnSettings
          }
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.selectedColumns,
                callback: function($$v) {
                  _vm.selectedColumns = $$v
                },
                expression: "selectedColumns"
              }
            },
            [
              _c(
                "div",
                { staticClass: "columns-list" },
                _vm._l(_vm.allColumns, function(column, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: column.field,
                      class: { "first-in-row": index % 5 === 0 },
                      attrs: { label: column.field },
                      on: {
                        change: function($event) {
                          return _vm.updateIsAllSelected()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(column.title) + " ")]
                  )
                }),
                1
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function($event) {
                      return _vm.handleAllSelectionChange()
                    }
                  },
                  model: {
                    value: _vm.isAllSelected,
                    callback: function($$v) {
                      _vm.isAllSelected = $$v
                    },
                    expression: "isAllSelected"
                  }
                },
                [_vm._v(" 全选 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeColumnSettings }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 318px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                data: _vm.list,
                height: "100%",
                "row-config": { isHover: true, isCurrent: true, height: 35 },
                "scroll-y": { enabled: true },
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.setSelectRows,
                "checkbox-change": _vm.setSelectRows
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  title: "序号",
                  type: "seq",
                  width: "70"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var seq = ref.seq
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              seq +
                                (_vm.queryForm.pageNum - 1) *
                                  _vm.queryForm.pageSize
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.selectedColumns.includes("cusProductCnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "cusProductCnName",
                      title: "客户中文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("cusProductEnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "cusProductEnName",
                      title: "客户英文品名",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportCnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportCnName",
                      title: "申报中文品名",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportEnName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportEnName",
                      title: "申报英文品名",
                      width: "220"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("hsCode")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "hsCode",
                      title: "中国海关编码",
                      width: "120"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("reportFactor")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "reportFactor",
                      title: "中国申报要素",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("materialCn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "materialCn",
                      title: "中文材质",
                      width: "140"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("materialEn")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "materialEn",
                      title: "英文材质",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("useTo")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "useTo",
                      title: "用途",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("description")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "description",
                      title: "商品描述",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("category")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "category",
                      title: "大类",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("chapter")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "chapter",
                      title: "章节",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("items")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "items",
                      title: "商品名称",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("regulatoryCondition")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "regulatoryCondition",
                      title: "监管条件",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("inspection")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "inspection",
                      title: "检验检疫",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("frequency")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "frequency",
                      title: "历史使用频次",
                      width: "120"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "vxe-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showFrequency(row)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.frequency) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1910845435
                    )
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("statusName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "statusName",
                      fixed: "right",
                      title: "状态",
                      width: "80"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.status === 0
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v(_vm._s(row.statusName))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              row.status === 1
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v(_vm._s(row.statusName))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              row.status === 2
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v(_vm._s(row.statusName))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1416679485
                    )
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createBy")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createBy",
                      title: "创建人",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("dataSourceName")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "dataSourceName",
                      title: "数据来源",
                      width: "100"
                    }
                  })
                : _vm._e(),
              _vm.selectedColumns.includes("createTime")
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      field: "createTime",
                      title: "创建时间",
                      width: "200"
                    }
                  })
                : _vm._e(),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  title: "操作",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.addOrEdit(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.details(row)
                              }
                            }
                          },
                          [_vm._v("明细")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _vm.isDetailsShow
        ? _c(
            "div",
            { staticClass: "posit" },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "15px",
                    top: "15px",
                    "z-index": "9999",
                    cursor: "pointer",
                    "font-size": "15px",
                    "font-weight": "600"
                  },
                  on: { click: _vm.formSmbiClose }
                },
                [
                  _vm._v(" 返回上一级 "),
                  _c("vab-icon", { attrs: { icon: "arrow-go-back-line" } })
                ],
                1
              ),
              _c("Details", {
                ref: "details",
                attrs: { id: _vm.id, dialog_visible: _vm.isDetailsShow },
                on: { dialogVisibleEvent: _vm.formSmbiClose }
              })
            ],
            1
          )
        : _vm._e(),
      _c("table-edit", {
        ref: "edit",
        attrs: {
          modulelist: _vm.queryList,
          "system-guid": _vm.queryForm.systemGuid
        }
      }),
      _c("frequency-modal", {
        attrs: { id: _vm.frequencyId, open: _vm.frequencyOpen },
        on: {
          "update:open": function($event) {
            _vm.frequencyOpen = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }