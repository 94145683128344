<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="140px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item label="" label-width="60px" prop="jobNo">
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item class="my-form-item" label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="子单号"
                prop="childJobNo"
              >
                <el-input
                  v-model="queryForm.childJobNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="是否混装"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="报关方式" prop="customsType">
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <!-- <el-form-item
                class="my-form-item"
                label="报关资料是否完整"
                prop="isCompletion"
              >
                <el-select
                  v-model="queryForm.isCompletion"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item> -->
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <div class="tools">
          <div>
            <!-- <el-button
          v-show="queryForm.status == 1"
          :disabled="!queryForm.idList.length > 0"
          size="mini"
          type="primary"
          @click="updateData"
        >
          更新
        </el-button> -->
            <el-button
              v-show="queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="confirm"
            >
              确认
            </el-button>
            <el-button
              v-show="queryForm.status == 2"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="danger"
              @click="cancel"
            >
              取消确认
            </el-button>
            <el-button
              v-show="queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="danger"
              @click="deleteData"
            >
              删除
            </el-button>
            <el-button
              v-show="queryForm.status == 0 || queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="createFile"
            >
              创建档案
            </el-button>
            <el-button
              v-show="queryForm.status == 2"
              :loading="loadingExPort"
              size="mini"
              type="primary"
              @click="exPort"
            >
              导出
            </el-button>
            <!-- <uploadExcel
          v-show="queryForm.status == 0"
          down-url="报关列表导入模版.xlsx"
          :upload-aciton="bt"
        /> -->
            <el-button
              v-show="queryForm.status == 2"
              :disabled="!queryForm.idList.length > 0"
              size="mini"
              type="primary"
              @click="updateD"
            >
              更新底单
            </el-button>
            <el-button size="mini" type="primary" @click="tmsDataSyncOpen">
              手动运单同步
            </el-button>
            <el-button
              v-show="queryForm.status == 0"
              :loading="loadingExPort2"
              size="mini"
              type="primary"
              @click="bottomOrderExport"
            >
              底单导出
            </el-button>
            <el-button
              v-show="queryForm.status == 0"
              :loading="loadingExPort"
              size="mini"
              type="primary"
              @click="exportOfCustomsDeclarationDocuments"
            >
              报关资料导出
            </el-button>
            <el-button
              v-show="queryForm.status == 0 || queryForm.status == 1"
              :disabled="!queryForm.idList.length > 0"
              :loading="loadingHsData"
              size="mini"
              type="primary"
              @click="mathHsData"
            >
              查询海关编码网站
            </el-button>
            <el-button size="mini" type="primary" @click="openColumnSettings">
              设置列
            </el-button>
          </div>
          <div>网站接口调用次数： {{ useTimes }}/5500</div>
        </div>
      </vab-query-form-left-panel>
    </vab-query-form>
    <!-- 列设置对话框 -->
    <el-dialog title="选择显示的列" :visible.sync="showColumnSettings" @close="closeColumnSettings">
      <el-checkbox-group v-model="selectedColumns">
        <div class="columns-list">
          <el-checkbox
            v-for="(column, index) in allColumns"
            :key="column.field"
            :class="{ 'first-in-row': index % 5 === 0 }"
            :label="column.field"
            @change="updateIsAllSelected()"
          >
            {{ column.title }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-checkbox
          v-model="isAllSelected"
          @change="handleAllSelectionChange()"
        >
          全选
        </el-checkbox>
        <el-button type="primary" @click="closeColumnSettings">关 闭</el-button>
      </span>
    </el-dialog>
    <el-tabs
      v-model="queryForm.status"
      style="width: 100%"
      type="card"
      @tab-click="queryList"
    >
      <el-tab-pane
        v-for="item in statusList"
        :key="item.id"
        :label="item.name"
        :name="item.id"
        style="height: 25px; line-height: 25px"
      />
    </el-tabs>
    <div style="height: calc(100vh - 470px)">
      <vxe-table
        ref="tableSort"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :data="tableData"
        height="100%"
        :row-config="{ isHover: true, isCurrent: true, height: 35 }"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
      >
        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          fixed="left"
          title="序号"
          type="seq"
          width="70"
        >
          <template #default="{ seq }">
            {{ seq + (queryForm.pageNum - 1) * queryForm.pageSize }}
          </template>
        </vxe-column>
        <vxe-column
          v-if="selectedColumns.includes('cabinetNo')"
          align="center"
          field="cabinetNo"
          title="柜号"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('jobNo')"
          align="center"
          field="jobNo"
          title="运单号"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('customerCode')"
          align="center"
          field="customerCode"
          title="客户"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('cusProductCnName')"
          align="center"
          field="cusProductCnName"
          title="客户中文品名"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('cusProductEnName')"
          align="center"
          field="cusProductEnName"
          title="客户英文品名"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('hsCode')"
          align="center"
          field="hsCode"
          title="海关编码"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('reportCnName')"
          align="center"
          field="reportCnName"
          title="申报中文品名"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('reportEnName')"
          align="center"
          field="reportEnName"
          title="申报英文品名"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('hsCodeCn')"
          align="center"
          field="hsCodeCn"
          title="中国海关编码"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('reportFactor')"
          align="center"
          field="reportFactor"
          title="中国申报要素"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('materialCn')"
          align="center"
          field="materialCn"
          title="中文材质"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('materialEn')"
          align="center"
          field="materialEn"
          title="英文材质"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('childJobNo')"
          align="center"
          field="childJobNo"
          show-overflow-tooltip
          title="子单号"
          width="240"
        />
        <vxe-column
          v-if="selectedColumns.includes('brand')"
          align="center"
          field="brand"
          title="品牌"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('brandType')"
          align="center"
          field="brandType"
          title="品牌类型"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('specs')"
          align="center"
          field="specs"
          title="规格"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('uses')"
          align="center"
          field="uses"
          title="用途"
          width="140"
        />
        <vxe-column
          v-if="selectedColumns.includes('goodsType')"
          align="center"
          field="goodsType"
          title="货物类型"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('unit')"
          align="center"
          field="unit"
          title="单位"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('pcs')"
          align="center"
          field="pcs"
          show-overflow-tooltip
          title="件数"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('netWeight')"
          align="center"
          field="netWeight"
          title="净重"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('receiveNetWeight')"
          align="center"
          field="receiveNetWeight"
          title="收货总净重"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('qty')"
          align="center"
          field="qty"
          title="数量"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('vol')"
          align="center"
          field="vol"
          show-overflow-tooltip
          title="体积"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('splitVol')"
          align="center"
          field="splitVol"
          show-overflow-tooltip
          title="拆分体积"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('grossWeight')"
          align="center"
          field="grossWeight"
          title="毛重"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('splitGrossWeight')"
          align="center"
          field="splitGrossWeight"
          title="拆分毛重"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('receiveGrossWeight')"
          align="center"
          field="receiveGrossWeight"
          title="收货总实重"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('spliTreceiveGrossWeight')"
          align="center"
          field="spliTreceiveGrossWeight"
          title="拆分收货总实重"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('purchasePrices')"
          align="center"
          field="purchasePrices"
          title="单价"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('totalPrice')"
          align="center"
          field="totalPrice"
          title="总价"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('length')"
          align="center"
          field="length"
          show-overflow-tooltip
          title="长"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('width')"
          align="center"
          field="width"
          show-overflow-tooltip
          title="宽"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('height')"
          align="center"
          field="height"
          show-overflow-tooltip
          title="高"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('transactionUrl')"
          align="center"
          field="transactionUrl"
          fixed="right"
          show-overflow-tooltip
          title="链接"
          width="80"
        >
          <template #default="{ row }">
            <a :href="row.transactionUrl" target="_blank">
              {{ row.transactionUrl }}
            </a>
          </template>
        </vxe-column>
        <vxe-column
          v-if="selectedColumns.includes('customsType')"
          align="center"
          field="customsType"
          title="报关方式"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('taxType')"
          align="center"
          field="taxType"
          title="清关方式"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('createBy')"
          align="center"
          field="createBy"
          title="创建人"
          width="100"
        />
        <vxe-column
          v-if="selectedColumns.includes('createTime')"
          align="center"
          field="createTime"
          title="创建时间"
          width="160"
        />
        <vxe-column
          v-if="selectedColumns.includes('dataSourceName')"
          align="center"
          field="dataSourceName"
          title="数据来源"
          width="120"
        />
        <vxe-column
          v-if="selectedColumns.includes('statusName')"
          align="center"
          field="statusName"
          title="状态"
          width="80"
        />
        <vxe-column
          v-if="selectedColumns.includes('isMixed')"
          align="center"
          field="isMixed"
          title="是否混装"
          width="160"
        />
        <vxe-column align="center" fixed="right" title="操作" width="160">
          <template #default="{ row }">
            <el-button
              :disabled="!row.pictureUrl"
              style="padding: 0px"
              type="text"
              @click="preview(row)"
            >
              图片预览
            </el-button>
            <el-button
              style="padding: 0px"
              type="text"
              @click="showSearchPanel(row)"
            >
              查看报关推荐
            </el-button>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>

    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight | toFixedNum }}</span>
      <span>订单净重：{{ fomr.totalNetWeight | toFixedNum }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight | toFixedNum }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight | toFixedNum }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice | toFixedNum }}</span>
      <span>体积：{{ fomr.totalVol | toFixedNum }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qtySum }}</span>
      <span>金额：{{ selectForm.priceSum }}</span>
      <span>体积：{{ selectForm.volSum }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Preview ref="preview" />
    <Sync ref="sync" />
    <search-panel
      :open.sync="showSearch"
      :search-info="searchInfo"
      @refresh="refreshTime"
      @save="queryList"
    />
  </div>
</template>

<script>
  import VabQueryForm from '@/vab/components/VabQueryForm/index.vue'

  let color_type = ''
  let color_lastIdList = []

  import {
    cancelClearanceList,
    clearanceListExportProfile,
    clearanceListMathHsData,
    confirmClearanceList,
    createFileClearanceList,
    deleteClearanceList,
    draftCopyExport,
    exportClearanceList,
    getOneCmsCount,
    importClearanceList,
    pageClearanceListTwo,
    pageTaskManage,
    updateClearanceList,
    updateDraftClearanceList,
  } from '@/api/cmsApi'

  import Preview from '@/vab/components/Preview'
  import throttle from 'lodash/throttle'
  import SearchPanel from './components/searchPanel.vue'
  import Sync from './components/sync'
  import VabQueryFormTopPanel from '@/vab/components/VabQueryForm/components/VabQueryFormTopPanel.vue'
  import VabQueryFormLeftPanel from '@/vab/components/VabQueryForm/components/VabQueryFormLeftPanel.vue'

  export default {
    name: 'ClearanceList',
    components: {
      VabQueryFormLeftPanel,
      VabQueryFormTopPanel,
      VabQueryForm,
      Preview,
      Sync,
      SearchPanel,
    },
    filters: {
      toFixedNum: function (value) {
        return value.toFixed(2)
      },
    },
    data() {
      return {
        statusList: [
          { id: '0', name: '全部' },
          { id: '1', name: '待确认' },
          { id: '2', name: '已确认' },
        ],
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        yseOrNo: ['是', '否'],
        tableData: [],
        pageList: [],
        fold: false,
        loadingExPort: false,
        loadingExPort2: false,
        loadingHsData: false,
        listLoading: false,

        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3) + 300,
        queryForm: {
          isCompletion: '',
          cabinetNo: '',
          isMixed: '',
          jobNo: '',
          childJobNo: '',
          customsType: '',
          taxType: '',
          status: 0,
          pageNum: 1,
          pageSize: 500,
          idList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: 0,
          receiveGrossWeight: 0,
          grossWeight: 0,
          netWeight: 0,
          pcs: 0,
          qtySum: 0,
          priceSum: 0,
          volSum: 0,
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
        //查询面板
        showSearch: false,
        //查询信息
        searchInfo: {
          id: '',
          reId: '',
        },
        useTimes: 0,
        showColumnSettings: false, // 控制设置对话框的显示
        allColumns: [
          { field: 'cabinetNo', title: '柜号' },
          { field: 'jobNo', title: '运单号' },
          { field: 'customerCode', title: '客户' },
          { field: 'cusProductCnName', title: '客户中文品名' },
          { field: 'cusProductEnName', title: '客户英文品名' },
          { field: 'hsCode', title: '海关编码' },
          { field: 'reportCnName', title: '申报中文品名' },
          { field: 'reportEnName', title: '申报英文品名' },
          { field: 'hsCodeCn', title: '中国海关编码' },
          { field: 'reportFactor', title: '中国申报要素' },
          { field: 'materialCn', title: '中文材质' },
          { field: 'materialEn', title: '英文材质' },
          { field: 'childJobNo', title: '子单号' },
          { field: 'brand', title: '品牌' },
          { field: 'brandType', title: '品牌类型' },
          { field: 'specs', title: '规格' },
          { field: 'uses', title: '用途' },
          { field: 'goodsType', title: '货物类型' },
          { field: 'unit', title: '单位' },
          { field: 'pcs', title: '件数' },
          { field: 'netWeight', title: '净重' },
          { field: 'receiveNetWeight', title: '收货总净重' },
          { field: 'qty', title: '数量' },
          { field: 'vol', title: '体积' },
          { field: 'splitVol', title: '拆分体积' },
          { field: 'grossWeight', title: '毛重' },
          { field: 'splitGrossWeight', title: '拆分毛重' },
          { field: 'receiveGrossWeight', title: '收货总实重' },
          { field: 'spliTreceiveGrossWeight', title: '拆分收货总实重' },
          { field: 'purchasePrices', title: '单价' },
          { field: 'totalPrice', title: '总价' },
          { field: 'length', title: '长' },
          { field: 'width', title: '宽' },
          { field: 'height', title: '高' },
          { field: 'customsType', title: '报关方式' },
          { field: 'taxType', title: '清关方式' },
          { field: 'createBy', title: '创建人' },
          { field: 'createTime', title: '创建时间' },
          { field: 'dataSourceName', title: '数据来源' },
          { field: 'statusName', title: '状态' },
          { field: 'isMixed', title: '是否混装' },
          { field: 'transactionUrl', title: '链接' },
        ],
        selectedColumns: [], // 用户选择的列
        isAllSelected: false, // 全选复选框的状态
      }
    },
    mounted() {
      // 默认选择所有列
      this.selectAllColumns()
    },
    created() {
      this.queryList()
    },
    methods: {
      selectAllColumns() {
        this.selectedColumns = this.allColumns.map((column) => column.field)
        this.isAllSelected = true
      },
      clearAllColumns() {
        this.selectedColumns = []
        this.isAllSelected = false
      },
      handleAllSelectionChange() {
        if (this.isAllSelected) {
          this.selectAllColumns()
        } else {
          this.clearAllColumns()
        }
      },
      updateIsAllSelected() {
        this.isAllSelected =
          this.selectedColumns.length === this.allColumns.length
      },
      openColumnSettings() {
        this.showColumnSettings = true
        this.updateIsAllSelected()
      },
      closeColumnSettings() {
        this.showColumnSettings = false
        this.queryList()
      },
      showSearchPanel(searchInfo) {
        this.showSearch = true
        this.searchInfo = searchInfo
      },
      selectAllEvent({ records }) {
        this.queryForm.idList = records.map((item) => item.id)
        this.selectForm.grossWeight = this.countTotal(records, 'grossWeight')
        this.selectForm.netWeight = this.countTotal(records, 'netWeight')
        this.selectForm.pcs = this.countTotalTwo(records, 'pcs')
        this.selectForm.qtySum = this.countTotalTwo(records, 'qtySum')
        this.selectForm.priceSum = this.countTotal(records, 'priceSum')
        this.selectForm.volSum = this.countTotal(records, 'volSum')
        this.selectForm.receiveNetWeight = this.countTotal(
          records,
          'receiveNetWeight'
        )
        this.selectForm.receiveGrossWeight = this.countTotal(
          records,
          'receiveGrossWeight'
        )
      },
      //选中计算
      countTotal(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total.toFixed(2)
      },
      countTotalTwo(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total
      },
      tmsDataSyncOpen() {
        this.$refs['sync'].show()
      },
      //图片预览
      preview(row) {
        this.$refs['preview'].showEdit(row)
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.idList = []
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.idList = []
        this.queryForm.pageNum = val
        this.queryList()
      },
      // 处理导出逻辑
      async handleExport(exportFunction, loadingState) {
        try {
          if (!this.queryForm.idList.length) {
            this.$message.warning('请勾选导出数据')
            return
          }
          this[loadingState] = true
          const res = await exportFunction(this.queryForm)
          this[loadingState] = false
          this.jump(res.data)
        } catch (error) {
          this[loadingState] = false
          this.$message.error('导出失败!')
        }
      },
      bottomOrderExport() {
        this.handleExport(draftCopyExport, 'loadingExPort2')
      },
      exportOfCustomsDeclarationDocuments() {
        this.handleExport(clearanceListExportProfile, 'loadingExPort')
      },
      async mathHsData() {
        let data = {
          idList: this.queryForm.idList,
        }
        try {
          this.loadingHsData = true
          const res = await clearanceListMathHsData(data)
          this.loadingHsData = false
          this.jump(res.data)
        } catch (error) {
          this.loadingHsData = false
        }
      },
      deleteData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【删除】勾选数据吗？', null, async () => {
          const res = await deleteClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      updateData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【更新】勾选数据吗？', null, async () => {
          const res = await updateClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      createFile() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm(
          '你确定全部【创建档案】勾选数据吗？',
          null,
          async () => {
            const res = await createFileClearanceList(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.queryList()
          }
        )
      },
      cancel() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm(
          '你确定全部【取消确认】勾选数据吗？',
          null,
          async () => {
            const res = await cancelClearanceList(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.queryList()
          }
        )
      },
      confirm() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【确认】勾选数据吗？', null, async () => {
          const res = await confirmClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      // 设置单元格样式
      cellClassName({ row, column }) {
        if (!row.hasGroup) {
          return null
        }
        if (column.fixed) {
          return null
        }

        let id = row.id
        if (!color_type) {
          color_type = 1
          color_lastIdList = row.idList
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        } else {
          color_lastIdList = row.idList
          if (color_type === 1) {
            color_type = 2
          } else {
            color_type = 1
          }
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      async throttleFn() {
        const result = await getOneCmsCount()
        this.useTimes = result?.data?.usageCounts

        this.queryForm.idList = []
        this.listLoading = true
        const res = await pageClearanceListTwo(this.queryForm)
        // 全部合计
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol
        // 选中数据合计
        this.selectForm.receiveNetWeight = 0
        this.selectForm.receiveGrossWeight = 0
        this.selectForm.grossWeight = 0
        this.selectForm.netWeight = 0
        this.selectForm.pcs = 0
        this.selectForm.qtySum = 0
        this.selectForm.priceSum = 0
        this.selectForm.volSum = 0

        this.total = res.data.totalSize
        this.tableData = res.data.pageList
        this.listLoading = false
      },
      async refreshTime() {
        const result = await getOneCmsCount()
        this.useTimes = result?.data?.usageCounts
      },
      //查询
      queryList: throttle(function () {
        this.throttleFn()
      }, 1000),
      doLayout() {
        let that = this
        this.$nextTick(() => {
          that.$refs.tableSort.doLayout()
        })
      },
      handleFold() {
        this.fold = !this.fold
      },
      updateD() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定【更新底单】勾选数据吗？', null, async () => {
          const res = await updateDraftClearanceList(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },

      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await exportClearanceList(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async bt(form) {
        try {
          const res = await importClearanceList(form)
          this.jump(res.data)
          this.queryList()
        } catch (error) {
          this.queryList()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .cont {
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
  }

  ::v-deep {
    // .el-tabs__item{
    //   height: 30px !important;
    //   line-height: 30px !important;
    // }
    // .el-button--small{
    //   padding: 0px 15px !important;
    // }
    .vab-query-form[data-v-64063760] .top-panel {
      margin: 0px;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .left-panel {
      margin: 0px !important;
    }
    .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }
    .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;
      &::before {
        background-color: transparent;
      }
    }
    .el-dialog__body {
      text-align: center;
    }
    .rowStyle {
      background-color: #e8f7fd !important;
      color: #1890ff;
    }
  }
  // .my-form-item {
  // padding-left: 10px !important;
  // }
  .cont-form {
    display: flex;
  }

  .tools {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
  }

  .columns-list {
    display: flex;
    flex-wrap: wrap;
  }

  .columns-list .el-checkbox {
    flex: 0 0 calc(20% - 10px); /* 每个复选框占1/5宽，减去间距 */
    margin: 5px;
    text-align: left; /* 首字符对齐 */
  }

  .columns-list .first-in-row {
    margin-left: 0; /* 第一个元素没有左边距 */
  }

  /* 确保全选复选框和按钮在同一行 */
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer .el-checkbox {
    margin-right: auto; /* 将全选复选框推到左侧 */
  }
</style>
